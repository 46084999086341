<template>
    <div>
        <HeaderBar />
        <Header steps="true" stepNumb="3" />
        <Steps step_a="3" completed="[true]" />
        <div class="background-grey background-form">
            <div class="container">
                <div class="bloque-informacion-basica form-paso3" id="paso3">
                    <div class="row align-items-start">
                        <div class="informacion-basica-titulo col">
                            <h2 class="informacion-basica-texto">{{$t('videoPresentation.title')}}</h2>
                            <div class="divider-wrap">
                                <div class="divider-middle mx-auto"></div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="saveAndContinue();scroll2error();" class="paso3" novalidate>



                            <div class="bloque-info-lines row">
                                <h2 class="bloque-info col-12 text-center"><span v-html="$t('academicResults.sectionTwo.title')"></span></h2>
                                <p class="bloque-info col-12 text-center">{{$t('academicResults.sectionTwo.subtitle')}}</p>
                            </div>

                            <div class="col-12 offset-xl-1 col-xl-11">
                                <div class="row">
                                    <div class="form-group col-12 col-xl-3 col-md-6 text-center-mobile">
                                        <label for="ingles-nativo" class=" ">{{$t('academicResults.sectionTwo.questionOne.title')}}</label>
                                        <div class="row">
                                            <label class="checkbox-container form-check offset-2 col-4 col-md-3 col-xl-3 optionsRatio">
                                                {{$t('academicResults.sectionTwo.questionOne.yes')}}
                                                <input v-model="good_english.native_or_bilingual" :value="true" type="radio" name="ingles-nativo">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="checkbox-container form-check col-4 col-md-3 col-xl-5">
                                                {{$t('academicResults.sectionTwo.questionOne.no')}}
                                                <input v-model="good_english.native_or_bilingual" :value="false" type="radio" name="ingles-nativo">
                                                <span class="checkmark"></span>
                                            </label>
                                            <div class="tooltip-error" v-if="v$.good_english.native_or_bilingual.$error">
                                                <div class="arrow"></div>
                                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-xl-4">
                                        <div class="form-group">
                                            <label class="form-required" for="english_level1">{{$t('academicResults.sectionThree.field2')}}</label>
                                            <select v-model="good_english.first.writing" class="form-control" :class="{'input-blue':good_english.first.writing!==null}" id="english_level1">
                                                <option v-for="(language, index) in levelsList1" :key="index" :value="language.valor">{{language.name}}</option>
                                            </select>
                                            <div class="tooltip-error" v-if="formchecked && (good_english.first.writing=='' || good_english.first.writing==null)">
                                                <div class="arrow"></div>
                                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-xl-4">
                                        <div class="form-group">
                                            <label class="form-required" for="english_level2">{{$t('academicResults.sectionThree.field3')}}</label>
                                            <select v-model="good_english.first.speaking" class="form-control" :class="{'input-blue':good_english.first.speaking!==null}" id="english_level2">
                                                <option v-for="(language, index) in levelsList2" :key="index" :value="language.valor">{{language.name}}</option>
                                            </select>
                                            <div class="tooltip-error" v-if="formchecked && (good_english.first.speaking=='' || good_english.first.speaking==null)">
                                                <div class="arrow"></div>
                                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-12 col-xl-11 col-md-6 text-center-mobile">
                                        <label for="estudiando-ingles" class="">{{$t('academicResults.sectionTwo.questionTwo.title')}}</label>
                                        <div class="row">
                                            <label class="checkbox-container form-check offset-2 col-4 col-md-3 col-xl-2 optionsRatio">
                                                {{$t('academicResults.sectionTwo.questionOne.yes')}}
                                                <input v-model="good_english.currently_studying_english" :value="true" type="radio" name="ingles-estudiando">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="checkbox-container form-check col-4 col-md-3 col-xl-5">
                                                {{$t('academicResults.sectionTwo.questionTwo.no')}}
                                                <input v-model="good_english.currently_studying_english" :value="false" type="radio" name="ingles-estudiando">
                                                <span class="checkmark"></span>
                                            </label>
                                            <div class="tooltip-error" v-if="v$.good_english.currently_studying_english.$error">
                                                <div class="arrow"></div>
                                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!good_english.native_or_bilingual && !good_english.currently_studying_english" class="col-12 offset-xl-1 col-xl-11">
                                <p for="" class="form-required label-text"><span v-html="$t('academicResults.sectionTwo.additionalDocumentation')"></span><span class='tooltip_container'><img src='@/assets/img/i_blue.svg' class='info-icon'><span class='tooltiptext' v-html="$t('academicResults.sectionTwo.additionalDocumentation_1')"></span></span><span v-html="$t('academicResults.sectionTwo.additionalDocumentation_2')"></span></p>
                                <div class="form-group text-center">
                                    <input v-model="good_english.additional_documentation.name" disabled type="text" id="adjuntar-additional-documentation" name="calificaciones" class="form-control col-md-6 col-xl-7" :class="{'input-blue':good_english.additional_documentation.name!==null}">
                                    <input @change="loadFile($event, 'additional_documentation')" type="file" class="form-control-file d-none" id="adjuntar-additional-documentation-file">
                                    <label for="adjuntar-additional-documentation-file" class="btn btn-primary btn-formulario btn-formulario-2 offset-md-1 col-md-5 col-xl-3 btn-float-right btn-width-md-5">
                                        {{$t('academicResults.sectionOne.button')}}
                                    </label>
                                    <div class="tooltip-error" v-if="formchecked && v$.good_english.additional_documentation.name.$error && v$.good_english.additional_documentation.name.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>
                                    <div class="tooltip-error" v-if="formchecked && v$.good_english.additional_documentation.$model.name !== null && v$.good_english.additional_documentation.name.extension.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileExtensionError')}}</span>
                                    </div>
                                    
                                    <div class="tooltip-error" v-if="formchecked && v$.good_english.additional_documentation.$model.file !== null && v$.good_english.additional_documentation.file.file_size_validation.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileSizeError')}}</span>
                                    </div>

                                </div>
                            </div>


                            <div class="bloque-info-lines row">
                                <h2 class="bloque-info col-12 text-center"><span v-html="$t('academicResults.sectionThree.title')"></span></h2>
                                <p class="bloque-info col-12 text-center">{{$t('academicResults.sectionThree.subtitle')}}</p>
                            </div>

                            <div v-if="additional_language > 1" class="row col-12 offset-xl-1 col-xl-10">
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language2">{{$t('academicResults.sectionThree.field1_2')}}</label>
                                        <select v-model="good_english.second.id" class="form-control" :class="{'input-blue': good_english.second.id!==null}" id="language2">
                                            <option v-for="(language, index) in languagesList1" :key="index" :value="language.id">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.second.id=='' || good_english.second.id==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language2_level1">{{$t('academicResults.sectionThree.field2')}}</label>
                                        <select v-model="good_english.second.writing" class="form-control" :class="{'input-blue':good_english.second.writing!==null}" id="language2_level1">
                                            <option v-for="(language, index) in levelsList1" :key="index" :value="language.valor">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.second.writing=='' || good_english.second.writing==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language2_level2">{{$t('academicResults.sectionThree.field3')}}</label>
                                        <select v-model="good_english.second.speaking" class="form-control" :class="{'input-blue':good_english.second.speaking!==null}" id="language2_level2">
                                            <option v-for="(language, index) in levelsList2" :key="index" :value="language.valor">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.second.speaking=='' || good_english.second.speaking==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>

                                    </div>
                                </div>

                            </div>


                            <div v-if="additional_language > 2" class="row col-12 offset-xl-1 col-xl-10">

                                <div class="w-100"></div>

                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language3">{{$t('academicResults.sectionThree.field1_3')}}</label>
                                        <select v-model="good_english.third.id" class="form-control" :class="{'input-blue':good_english.third.id!==null}" id="language3">
                                            <option v-for="(language, index) in languagesList2" :key="index" :value="language.id">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.third.id=='' || good_english.third.id==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language3_level1">{{$t('academicResults.sectionThree.field2')}}</label>
                                        <select v-model="good_english.third.writing" class="form-control" :class="{'input-blue':good_english.third.writing!==null}" id="language3_level1">
                                            <option v-for="(language, index) in levelsList1" :key="index" :value="language.valor">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.third.writing=='' || good_english.third.writing==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language3_level2">{{$t('academicResults.sectionThree.field3')}}</label>
                                        <select v-model="good_english.third.speaking" class="form-control" :class="{'input-blue':good_english.third.speaking!==null}" id="language3_level2">
                                            <option v-for="(language, index) in levelsList2" :key="index" :value="language.valor">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.third.speaking=='' || good_english.third.speaking==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div v-if="additional_language > 3" class="row col-12 offset-xl-1 col-xl-10">

                                <div class="w-100"></div>

                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language4">{{$t('academicResults.sectionThree.field1_4')}}</label>
                                        <select v-model="good_english.fourth.id" class="form-control" :class="{'input-blue':good_english.fourth.id!==null}" id="language4">
                                            <option v-for="(language, index) in languagesList3" :key="index" :value="language.id">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.fourth.id=='' || good_english.fourth.id==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language4_level1">{{$t('academicResults.sectionThree.field2')}}</label>
                                        <select v-model="good_english.fourth.writing" class="form-control" :class="{'input-blue':good_english.fourth.writing!==null}" id="language4_level1">
                                            <option v-for="(language, index) in levelsList1" :key="index" :value="language.valor">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.fourth.writing=='' || good_english.fourth.writing==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="form-required" for="language4_level2">{{$t('academicResults.sectionThree.field3')}}</label>
                                        <select v-model="good_english.fourth.speaking" class="form-control" :class="{'input-blue':good_english.fourth.speaking!==null}" id="language4_level2">
                                            <option v-for="(language, index) in levelsList2" :key="index" :value="language.valor">{{language.name}}</option>
                                        </select>
                                        <div class="tooltip-error" v-if="formchecked && (good_english.fourth.speaking=='' || good_english.fourth.speaking==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div v-if="additional_language > 1" class=" col-12 offset-xl-1 col-xl-10 force-z-index">
                                <div class="container-delete-parent2">
                                    <a @click="deleteLanguage()" class="img-delete"><img src="@/assets/img/delete_block_parent.png" alt="" class="float-right"></a>
                                </div>
                            </div>

                            <div class="container" v-if="additional_language < 4">
                                <div class="col-12 offset-xl-1">
                                    <div class="btn-add text-center-mobile">
                                        <button @click="addOtherLanguage()" type="button" class="btn btn-primary btn-formulario-two col-md-1 col-xl-1 btn-width-md-5"> {{$t('academicResults.sectionThree.addButton')}}</button>
                                    </div>
                                </div>
                            </div>











<!--                            <h2 class="bloque-info col-12 text-center  col-xl-11"><b>{{$t('videoPresentation.sectionTwo.title')}}</b></h2>
                            <p class="bloque-info col-12 text-center" v-html="$t('videoPresentation.sectionTwo.subtitle')"></p>
                            <div class="col-12 col-md-4 offset-xl-1">
                                <div class="form-group">
                                    <label for="ensayo-tema" class="form-required">{{$t('videoPresentation.sectionTwo.selectTopic')}}</label>
                                    <select v-model="are_you_working.topic" class="form-control" :class="{'input-blue':are_you_working.topic!==null}" id="parent-pais">
                                        <option v-for="(topic, index) in topics" :key="index" :value="topic.topic_id">{{topic.nombre}}</option>
                                    </select>
                                    <div class="tooltip-error" v-if="v$.are_you_working.topic.$error">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <p for="" class="form-required label-text label-text-adjust">{{$t('videoPresentation.sectionTwo.yourEssay')}}</p>
                                <div class="form-group text-center">
                                    <input v-model="are_you_working.documentation.name" disabled type="text" id="adjuntar-ensayo" name="calificaciones" class="form-control btn-margin-extra col-md-6 col-xl-7" :class="{'input-blue':are_you_working.documentation.name!==null}">
                                    <input @change="loadFile($event, 'documentation')" type="file" class="form-control-file d-none" id="adjuntar-ensayo-presentacion">
                                    <label for="adjuntar-ensayo-presentacion" class="btn btn-primary btn-formulario btn-float-right btn-width-md-5 btn-top-adjust adjuntar-ensayo-presentacion">
                                        {{$t('videoPresentation.sectionTwo.button')}}
                                    </label>
                                    <div class="tooltip-error" v-if="v$.are_you_working.documentation.name.$error && v$.are_you_working.documentation.name.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>
                                    <div class="tooltip-error" v-if="v$.are_you_working.documentation.$model.name !== null && v$.are_you_working.documentation.name.extension.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileExtensionError')}}</span>
                                    </div>
                                </div>
                            </div>-->


                        <div class="row align-items-start">

                            <h2 class="bloque-info col-12 text-center"><b>{{$t('videoPresentation.sectionFour.title')}}</b></h2>
                            <p class="bloque-info col-12 text-center col-xl-11">{{$t('videoPresentation.sectionFour.subtitle')}}</p>
                            <div v-for="(international, index) in international_experience" :key="index" class="col-12 relative">

                                <div class="col-12 offset-xl-1 col-xl-10">
                                    <div class="container-delete-parent">
                                        <a @click="removeInternationalExperiences(index)" class="img-delete"><img src="@/assets/img/delete_block_parent.png" alt="" class="float-right"></a>
                                    </div>
                                </div>
                                <div class="row col-12">
                                    <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                                        <div class="form-group">
                                            <label for="international-country" class="form-required">{{$t('videoPresentation.sectionFour.country')}}</label>
                                            <select v-model="international.country" class="form-control" :class="{'input-blue':international.country!==null}" id="international-country">
                                                <option v-for="(country, index) in countries" :key="index" :value="country.mec_code">{{country.nombre}}</option>
                                            </select>
                                            <div class="tooltip-error" v-if="formchecked && (international.country=='' || international.country==null)">
                                                <div class="arrow"></div>
                                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 col-xl-1">
                                        <div class="form-group">
                                            <label for="international-duration" class="form-required">{{$t('videoPresentation.sectionFour.duration')}}</label>
                                            <input v-model.trim="international.duration" class="form-control" :class="{'input-blue':international.duration!==null&&international.duration!==''}" id="international-duration" rows="1" data-toggle="tooltip" data-placement="bottom">

                                            <div class="tooltip-error narrow-error" v-if="formchecked && (international.duration=='' || international.duration==null)">
                                                <div class="arrow"></div>
                                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                            </div>
                                            <div class="tooltip-error narrow-error" v-if="(formchecked && (isNaN(international.duration) || international.duration.split('.').length>1 || international.duration== '0'))">
                                                <div class="arrow"></div>
                                                <span class="tooltip-text">{{$t('errors.fieldNumeric')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 offset-xl-1 col-xl-10">
                                    <div class="form-group">
                                        <label for="international-textarea" class="form-required">{{$t('videoPresentation.sectionFour.shortDescription')}}</label>
                                        <textarea v-model.trim="international.description" class="form-control textarea"  maxlength="2000" :class="{'input-blue':international.description!==null}" id="international-textarea" rows="10"></textarea>
                                        <span class="maxLengthField">{{$t('videoPresentation.sectionFour.shortDescriptionMax')}}</span>

                                        <div class="tooltip-error" v-if="formchecked && (international.description=='' || international.description==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>
                                        <div class="tooltip-error" v-if="formchecked && (international.description!=null) && (international.description.length > 2000)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 2000</span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="container">
                                <div class="col-12 offset-xl-1">
                                    <div class="btn-add text-center-mobile">
                                        <button @click="addInternationalExperiences()" type="button" class="btn btn-primary btn-formulario-two  col-md-5 col-xl-3 btn-width-md-5 "> {{$t('videoPresentation.sectionFour.addMore')}}</button>
                                    </div>
                                </div>
                            </div>




                            <h2 class="bloque-info col-12 text-center"><b>{{$t('videoPresentation.sectionThree.title')}}</b></h2>
                            <p class="bloque-info col-12 text-center col-xl-11">{{$t('videoPresentation.sectionThree.subtitle')}}</p>
                            <div v-for="(extracurricular, index) in extracurricular_activities" :key="index" class="col-12 relative">

                                <div v-if="index>0" class=" col-12 offset-xl-1 col-xl-10">
                                    <div class="container-delete-parent">
                                        <a v-if="!disableParentTwo" @click="removeExtracurricular(index)" class="img-delete"><img src="@/assets/img/delete_block_parent.png" alt="" class="float-right"></a>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12 offset-xl-1 col-xl-10">
                                    <div class="form-group">
                                        <label for="titulo-extracurricular" class="form-required">{{$t('videoPresentation.sectionThree.titleActivity')}}</label>
                                        <input v-model.trim="extracurricular.title" class="form-control" :class="{'input-blue':extracurricular.title!==null}" id="titulo-extracurricular" rows="1" data-toggle="tooltip" data-placement="bottom">
                                        <div class="tooltip-error" v-if="formchecked && (extracurricular.title=='' || extracurricular.title==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>
                                        <div class="tooltip-error" v-if="formchecked && (extracurricular.title !=null) && (extracurricular.title.length > 100)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12 offset-xl-1 col-xl-10">
                                    <div class="form-group">
                                        <label for="extracurriculares-textarea" class="form-required">{{$t('videoPresentation.sectionThree.shortDescription')}}</label>
                                        <textarea v-model.trim="extracurricular.short_description" maxlength="2000" class="form-control textarea" :class="{'input-blue':extracurricular.short_description!==null}" id="extracurriculares-textarea" rows="10"></textarea>
                                        <span class="maxLengthField">{{$t('videoPresentation.sectionThree.shortDescriptionMax')}}</span>
                                        <div class="tooltip-error" v-if="formchecked && (extracurricular.short_description=='' || extracurricular.short_description==null)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                        </div>
                                        <div class="tooltip-error" v-if="formchecked && (extracurricular.short_description!=null) && (extracurricular.short_description.length > 2000)">
                                            <div class="arrow"></div>
                                            <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 2000</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12 offset-xl-1 col-xl-10 relative">
                                    <p class="label-text">{{$t('videoPresentation.sectionThree.documentation')}}</p>
                                    <div class="form-group text-center-mobile">
                                        <div class="row">
                                            <div class="col-12 relative extra-line">
                                                <input v-model="extracurricular.documentation.name" disabled type="text" v-bind:id="'adjuntar-documento-extra-'+index" name="calificaciones" class="form-control btn-margin-extra col-md-4 col-xl-4" :class="{'input-blue':extracurricular.documentation.name!==null}">
                                                <input @change="loadFile($event, 'documentations')" type="file" class="form-control-file d-none" v-bind:id="'adjuntar-documento-extra-presentacion-'+index">
                                                <label @click="addIndex(index)" v-bind:for="'adjuntar-documento-extra-presentacion-'+index" class="btn btn-primary btn-formulario-two col-md-5 col-xl-3 btn-width-md-5 btn-formulario-two-margin-left btn-float-right">
                                                    {{$t('videoPresentation.sectionThree.button')}}
                                                </label>
                                                <div class="tooltip-error" v-if="(extracurricular.documentation.name!='' && extracurricular.documentation.name!=null && extracurricular.documentation.name.search('.jpg')==-1 && extracurricular.documentation.name.search('.pdf')==-1)">
                                                    <div class="arrow"></div>
                                                    <span class="tooltip-text">{{$t('errors.fileExtensionError')}}</span>
                                                </div>
                                                <div class="tooltip-error" v-if="(extracurricular.documentation.file!=null && (extracurricular.documentation.file.length>10485760 || extracurricular.documentation.file.length<1))">
                                                    <div class="arrow"></div>
                                                    <span class="tooltip-text">{{$t('errors.fileSizeError')}}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container">
                                <div class="col-12 offset-xl-1">
                                    <div class="btn-add text-center-mobile">
                                        <button @click="addExtracurricular()" type="button" class="btn btn-primary btn-formulario-two  col-md-5 col-xl-3 btn-width-md-5 "> {{$t('videoPresentation.sectionThree.addMore')}}</button>
                                    </div>
                                </div>
                            </div>






<!--                            <h2 class="bloque-info col-12 text-center"><b>{{$t('videoPresentation.sectionFive.title')}}</b></h2>
                            <p class="bloque-info col-12 text-center col-xl-11 form-required">{{$t('videoPresentation.sectionFive.subtitle')}}</p>
                            <div class="col-12 col-md-6 col-xl-3">
                                <div class="form-group">
                                    <label v-for="(univ) in likely_universities" :key="univ.code" class="checkbox-container form-check">
                                        {{univ.name}}
                                        <input v-model="university_goal" :value="univ.code" type="radio" name="likely-univ">
                                        <span class="checkmark"></span>
                                    </label>

                                    <div class="tooltip-error" v-if="formchecked && v$.university_goal.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>
                                </div>
                            </div>-->





                            <div class="col-12 offset-md-1 col-md-11 offset-md-1 offset-xl-2 col-xl-9">
                                <button @click="backStep()" type="button" class="rounded-pill btn-lg btn-primary mb-2 form-btn-back col-md-5">{{$t('videoPresentation.buttons.back')}}</button>
                                <button type="submit" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit offset-md-1 offset-xl-1 col-md-5">{{$t('videoPresentation.buttons.save')}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <ModalExpireToken v-if="expireToken" />
        <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />
        <ModalWorking v-if="showModalWorking" :show-modal-working="showModalWorking" />
        <Footer />
    </div>
</template>

<script>

    import { mapState } from 'vuex'
    import ModalExpireToken from '@/components/ModalExpireToken.vue'
    import ModalServiceError from '@/components/ModalServiceError.vue'
    import ModalWorking from '@/components/ModalWorking.vue'
    import HeaderBar from '@/components/HeaderBar.vue'
    import Header from '@/components/Header.vue'
    import Steps from '@/components/Steps.vue'
    import Footer from '@/components/Footer.vue'
    import useVuelidate from '@vuelidate/core'
    import { required } from "@vuelidate/validators"
    import shared from '@/shared'


    const extension = (value) => {
        var re = /(\.jpg|\.pdf)$/i;
        return (re.exec(value)) ? true : false;
    }

    const file_size_validation = (value) =>  {
        if (value==null) {
            return true;
        }
        else if (!value) {
            return false;
        }
        let file = value;
        return (file.length < 10485760 && file.length > 0);
    };


    export default {
        name: 'VideoPresentation',
        components: {
            HeaderBar,
            Header,
            Steps,
            Footer,
            ModalExpireToken,
            ModalServiceError,
            ModalWorking
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {

                good_english: {
                    native_or_bilingual: false,
                    currently_studying_english: false,
                    //writing: null,
                    //speaking: null,
                    additional_documentation: {
                        name: null,
                        file: null,
                        delete: false
                    },
                    first: {
                        id: null,
                        writing: null,
                        speaking: null
                    },
                    second: {
                        id: null,
                        writing: null,
                        speaking: null
                    },
                    third: {
                        id: null,
                        writing: null,
                        speaking: null
                    },
                    fourth: {
                        id: null,
                        writing: null,
                        speaking: null
                    }
                },
                languagesList: null,
                levelsList1: null,
                levelsList2: null,
                additional_language: 1,
                are_you_working: {
                    topic: null,
                    documentation: {
                        name: null,
                        file: null,
                        delete: false
                    }
                },
                international_experience: [],
                //        {
                //        id: null,
                //        country: null,
                //        duration: null,
                //        description: null,
                //        name: null
                //      }],
                extracurricular_activities: [{
                    title: null,
                    short_description: null,
                    documentation: {
                        name: null,
                        file: null,
                        delete: false
                    }
                }],
//                university_goal: null,
//                topics: [],
//                likely_universities: [{ name: 'USA-Canada', code: 141180000 }, { name: 'UK', code: 141180001 }, { name: 'Spain', code: 141180002 }, { name: 'France', code: 141180003 }, { name: 'Netherlands', code: 141180004 }, { name: 'Other European country', code: 141180008 }, { name: 'Asia Pacific', code: 141180005 }, { name: 'Africa', code: 141180006 }, { name: 'Latin America', code: 141180009 }, { name: 'Undecided', code: 141180007 }],
                indexDocumentation: null,
                expireToken: false,
                serviceError: false,
                serviceErrorMsg: null,
                showModalWorking: true,
                formchecked: false,
                countries: []
            }
        },
        validations() {
            if (!this.good_english.native_or_bilingual && !this.good_english.currently_studying_english)
            {
                return{

                    good_english: {
                        native_or_bilingual: { required },
                        currently_studying_english: { required },
                        first: {
                            writing: { required },
                            speaking: { required }
                        },
                        additional_documentation: {
                            name: {
                                required,
                                extension
                            },
                            file:{
                                file_size_validation
                            }

                        }
                    },
//                    university_goal: { required },
//                    are_you_working: {
//                        topic: { required },
//                        documentation: {
//                            name: {
//                                required,
//                                extension
//                            }
//                        }
//                    }                    

                }
            }
            else
            {
                return{

                    good_english: {
                        native_or_bilingual: { required },
                        currently_studying_english: { required },
                        first: {
                            writing: { required },
                            speaking: { required }
                        }
                    },
//                    university_goal: { required },
//                    are_you_working: {
//                        topic: { required },
//                        documentation: {
//                            name: {
//                                required,
//                                extension
//                            }
//                        }
//                    }                    

                }

            }


        },
        computed: {
            ...mapState([
                'step',
                'msalConfig',
                'enviroments',
                'VideoPresentation',
                'idAdmission'
            ]),
            account() {
                return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined;
            },
            accessToken() {
                return Object.keys(this.msalConfig.accessToken).length !== 0 ? this.msalConfig.accessToken : undefined;
            },
            languagesList1() {
                return this.languagesList.filter(u => (u.id != this.good_english.third.id && u.id != this.good_english.fourth.id)).sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            },
            languagesList2() {
                return this.languagesList.filter(u => (u.id != this.good_english.second.id && u.id != this.good_english.fourth.id)).sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            },
            languagesList3() {
                return this.languagesList.filter(u => (u.id != this.good_english.second.id && u.id != this.good_english.third.id)).sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            }
        },
        mounted() {

           this.normalize_name = shared.normalize_name;

            window.scrollTo(0, 0);
            this.getVideoPresentation();
//            this.getTopic();
            this.getLanguages();

//            this.$store.watch(() => {
//                return this.$store.state.VideoPresentation.are_you_working
//            }, (res) => {
//                if ((res === undefined) || (res === null)) return;
//                this.are_you_working = res;
//            })
            this.$store.watch(() => {
                return this.$store.state.VideoPresentation.extracurricular_activities
            }, (res) => {
                if ((res === undefined) || (res === null) || (res.length === 0)) return;
                this.extracurricular_activities = res;
            })
            this.$store.watch(() => {
                return this.$store.state.VideoPresentation.international_experience
            }, (res) => {
                if ((res === undefined) || (res === null) || (res.length === 0)) return;
                this.international_experience = res;
            })

            this.$store.watch(() => {
                return this.$store.state.VideoPresentation.good_english
            }, (res) => {
                if ((res === undefined) || (res === null)) return;
                this.good_english = res;
            })



            this.getCountries();

        },
        methods: {
            getVideoPresentation() {

                this.axios.get(`${this.enviroments.urlDev}/Motivation?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {

                    this.showModalWorking = false;
                    document.body.style.overflowY = "auto";

                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    }
                    else {

                        for (let i = 0; i < response.data.extracurricular_activities.length; i++) {
                            if (response.data.extracurricular_activities[i].documentation == null) { response.data.extracurricular_activities[i].documentation = { 'name': null,'file': null, 'delete': false } }
                        }

//                        if (response.data.are_you_working === null) { response.data.are_you_working = { 'topic': null, 'documentation': { 'name': null, 'file': null, 'delete': false } } }
//                        if (response.data.are_you_working.documentation === null) { response.data.are_you_working.documentation = { 'name': null, 'file': null, 'delete': false } }



                        if ((response.data.good_english !== null) && (response.data.good_english.additional_documentation.name == "")) { response.data.good_english.additional_documentation.name = null }
                        if ((response.data.good_english !== null) && (response.data.good_english.additional_documentation === null)) { response.data.good_english.additional_documentation = { 'name': null, 'file': null, 'delete': false }; }
                        if (response.data.good_english.first === null) { response.data.good_english.first = { 'id': null, 'writing': null, 'speaking': null }; }
                        if (response.data.good_english.second === null) { response.data.good_english.second = { 'id': null, 'writing': null, 'speaking': null }; }
                        if (response.data.good_english.third === null) { response.data.good_english.third = { 'id': null, 'writing': null, 'speaking': null }; }
                        if (response.data.good_english.fourth === null) { response.data.good_english.fourth = { 'id': null, 'writing': null, 'speaking': null }; }

                        if (response.data.good_english.fourth.id !== null) { this.additional_language = 4 }
                        else if (response.data.good_english.third.id !== null) { this.additional_language = 3 }
                        else if (response.data.good_english.second.id !== null) { this.additional_language = 2 }

                        this.$store.commit('VideoPresentation/setGoodEnglish', response.data.good_english)


//                        this.$store.commit('VideoPresentation/setPresentation', response.data.presentation)
//                        this.presentation = response.data.presentation;


//                        this.university_goal = response.data.university_goal;
//                        this.$store.commit('VideoPresentation/setAreYouWorking', response.data.are_you_working)
                        this.$store.commit('VideoPresentation/setExtracurricularActivities', response.data.extracurricular_activities)
                        this.$store.commit('VideoPresentation/setInternationalExperiences', response.data.international_experience)
                    }

                })
                    .catch(function (error) {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `"ERROR:: VIDEO PRESENTATION get Video Presentation: ${error}`;
                    });
            },
            getCountries() {

                let langParam = "";
                if (this.$i18n.locale == 'es') { langParam = "&idioma=es"; }

                this.axios.get(`${this.enviroments.urlDev}/Country?token=${this.accessToken}${langParam}`).then(response => {
                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    }
                    else if (response.data.status === 0) {
                        this.countries = response.data.countries_matches;
                    }
                })
                    .catch(error => {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `ERROR:: BASIC INFORMATION get Countries: ${error}`;
                    });
            },
            setVideoPresentation() {


                if (this.good_english.additional_documentation.name != null && this.good_english.additional_documentation.name != '')
                { this.good_english.additional_documentation.name = this.normalize_name(this.good_english.additional_documentation.name)}


                this.showModalWorking = true;
                document.body.style.overflowY = "hidden";

                this.axios.post(`${this.enviroments.urlDev}/Motivation?token=${this.accessToken}&id_admision=${this.idAdmission}`, {
//                    presentation: this.presentation,
//                    are_you_working: this.are_you_working,
//                    university_goal: this.university_goal,
                    extracurricular_activities: this.extracurricular_activities,
                    international_experience: this.international_experience,
                    good_english: this.good_english,
                }).then(response => {

                    this.showModalWorking = false;

                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    } else if (response.data.status === 0) {
                        this.$router.push('recommendations-teamworks');
                        this.step.firstStep = false;
                        this.step.step3Completed = true;

                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `"ERROR:: VIDEO PRESENTATION set Video Presentation: ${error}`;
                    });
            },
            loadFile(e, param) {

                let file = e.target.files[0]
                console.log(file)

                let reader = new FileReader()

                reader.onload = (e) => {
                    if (param === 'documentation') {
                        this.are_you_working.documentation = {
                            name: file.name,
                            delete: false,
                            file: e.target.result.replace("data:image/jpeg;base64,", "").replace("data:application/pdf;base64,", "")
                        }
                    }
                    if (param === 'documentations') {
                        let documentation = {
                            name: file.name,
                            delete: false,
                            file: e.target.result.replace("data:image/jpeg;base64,", "").replace("data:application/pdf;base64,", "")
                        }
                        this.extracurricular_activities[this.indexDocumentation].documentation = documentation
                    }
                    if (param === 'additional_documentation') {
                        this.good_english.additional_documentation = {
                            name: file.name,
                            delete: false,
                            file: e.target.result.replace("data:image/jpeg;base64,", "").replace("data:application/pdf;base64,", "")
                        }
                    }


                }

                reader.readAsDataURL(file);
            },
            addExtracurricular() {
                this.extracurricular_activities.push({
                    title: null,
                    short_description: null,
                    documentation: {
                        name: null,
                        file: null,
                        delete: false
                    }
                })
            },
            removeExtracurricular(item) {
                this.extracurricular_activities.splice(item, 1)
            },
            addInternationalExperiences() {
                this.international_experience.push({
                    id: null,
                    country: null,
                    duration: '',
                    description: null,
                    name: null
                })
            },
            removeInternationalExperiences(item) {
                this.international_experience.splice(item, 1)
            },
            addIndex(index) {
                this.indexDocumentation = index;
            },
            scroll2error() {
                setTimeout(function () { if (document.getElementsByClassName('tooltip-error').length != 0) { document.getElementsByClassName('tooltip-error')[0].parentElement.scrollIntoView(); } }.bind(this), 100)
            },
            saveAndContinue() {
                this.v$.$touch()
                this.formchecked = true;
                let extra_errors = false;

                for (let i = 0; i < this.extracurricular_activities.length; i++) {
                    if (
                        (this.extracurricular_activities[i].title == null) || (this.extracurricular_activities[i].title == '') || (this.extracurricular_activities[i].title.length > 100) ||
                        (this.extracurricular_activities[i].short_description == '') || (this.extracurricular_activities[i].short_description == null) || (this.extracurricular_activities[i].short_description.length > 2000) ||
                        ((this.extracurricular_activities[i].documentation.name != '' && this.extracurricular_activities[i].documentation.name != null && this.extracurricular_activities[i].documentation.name.search('.jpg') == -1 && this.extracurricular_activities[i].documentation.name.search('.pdf') == -1)) ||
                        (this.extracurricular_activities[i].documentation.file!=null && (this.extracurricular_activities[i].documentation.file.length>10485760 || this.extracurricular_activities[i].documentation.file.length<1))
                    ) { extra_errors = true; }
                }

                for (let i = 0; i < this.international_experience.length; i++) {
                    if ((this.international_experience[i].country == '') || (this.international_experience[i].country == null) ||
                        (this.international_experience[i].duration == '') || (this.international_experience[i].duration == null) || (this.international_experience[i].duration == '0') ||
                        (this.international_experience[i].description == '') || (this.international_experience[i].description == null) || (isNaN(this.international_experience[i].duration)) || (this.international_experience[i].duration.split('.').length > 1) || (this.international_experience[i].description.length > 2000)) { extra_errors = true; }
                }


                if ((this.good_english.first.writing == '') || (this.good_english.first.writing == null)) { extra_errors = true; }
                if ((this.good_english.first.speaking == '') || (this.good_english.first.speaking == null)) { extra_errors = true; }

                if ((this.additional_language === 2) && ((this.good_english.second.id == '') || (this.good_english.second.id == null))) { extra_errors = true; }
                if ((this.additional_language === 2) && ((this.good_english.second.writing == '') || (this.good_english.second.writing == null))) { extra_errors = true; }
                if ((this.additional_language === 2) && ((this.good_english.second.speaking == '') || (this.good_english.second.speaking == null))) { extra_errors = true; }

                if ((this.additional_language === 3) && ((this.good_english.third.id == '') || (this.good_english.third.id == null))) { extra_errors = true; }
                if ((this.additional_language === 3) && ((this.good_english.third.writing == '') || (this.good_english.third.writing == null))) { extra_errors = true; }
                if ((this.additional_language === 3) && ((this.good_english.third.speaking == '') || (this.good_english.third.speaking == null))) { extra_errors = true; }

                if ((this.additional_language === 4) && ((this.good_english.fourth.id == '') || (this.good_english.fourth.id == null))) { extra_errors = true; }
                if ((this.additional_language === 4) && ((this.good_english.fourth.writing == '') || (this.good_english.fourth.writing == null))) { extra_errors = true; }
                if ((this.additional_language === 4) && ((this.good_english.fourth.speaking == '') || (this.good_english.fourth.speaking == null))) { extra_errors = true; }

                if ( this.v$.good_english.$error || extra_errors) { return false; }
                console.log(extra_errors)
                this.setVideoPresentation();

            },
/*            getTopic() {
                this.axios.get(`${this.enviroments.urlDev}/Topic?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {

                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    }
                    else {
                        this.topics = response.data.topics_matches;
                    }

                })
                    .catch(function (error) {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `"ERROR:: VIDEO PRESENTATION get Topic: ${error}`;

                    });
            },*/
            modalErrorClose() { this.serviceError = false },
            backStep() {
                this.$router.push('academic-results');
                this.step.firstStep = false;
            },
            addOtherLanguage() {
                if (this.additional_language < 4)
                    this.additional_language++;
            },
            deleteLanguage() {
                console.log("pulsa");
                if (this.additional_language > 1) { this.additional_language--; }
                if (this.additional_language === 3) {
                    this.good_english.fourth.id = null;
                    this.good_english.fourth.writing = null;
                    this.good_english.fourth.speaking = null;
                }
                else if (this.additional_language === 2) {
                    this.good_english.third.id = null;
                    this.good_english.third.writing = null;
                    this.good_english.third.speaking = null;
                }
                else if (this.additional_language === 1) {
                    this.good_english.second.id = null;
                    this.good_english.second.writing = null;
                    this.good_english.second.speaking = null;
                }
            },
            getLanguages() {

                //        let langParam = "";
                //        if (this.$i18n.locale == 'es')
                //        {langParam = "&idioma=es";}


                this.axios.get(`${this.enviroments.urlDev}/Language?token=${this.accessToken}`).then(response => {
                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    }
                    else if (response.data.status === 0) {
                        this.languagesList = response.data.languages_matches;
                    }
                })
                    .catch(error => {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `ERROR:: BASIC INFORMATION get Countries: ${error}`;
                    });

                this.axios.get(`${this.enviroments.urlDev}/OptionSetRequest?token=${this.accessToken}&ent=iegc_personlanguajes&field=iegc_levelofproficiencywriting`).then(response => {
                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    }
                    else if (response.data.status === 0) {
                        this.levelsList1 = response.data.results;
                    }
                })
                    .catch(error => {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `ERROR:: BASIC INFORMATION get Countries: ${error}`;
                    });


                this.axios.get(`${this.enviroments.urlDev}/OptionSetRequest?token=${this.accessToken}&ent=iegc_personlanguajes&field=iegc_levelofproficiencyspeaking`).then(response => {
                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    }
                    else if (response.data.status === 0) {
                        this.levelsList2 = response.data.results;
                    }
                })
                    .catch(error => {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `ERROR:: BASIC INFORMATION get Countries: ${error}`;
                    });
            }


        }
    }
</script>
