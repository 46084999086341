export default {
    normalize_name: function(value) { 

      value=value.replace("#", "")
      value=value.replace("%", "")
      value=value.replace("&", "")
      value=value.replace("*", "")
      value=value.replace("{", "")
      value=value.replace("}", "")
      value=value.replace("\\", "")
      value=value.replace(":", "")
      value=value.replace("<", "")
      value=value.replace(">", "")
      value=value.replace("?", "")
      value=value.replace("/", "")
      value=value.replace("+", "")
      value=value.replace("|", "")
      value=value.replace("@", "")

      return (value);

    }  
  }